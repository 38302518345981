import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes : Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'LoginApp',
        component: () => import('../views/LoginApp.vue')
    },
    {
        path: '/',
        name: 'LayOut',
        component: () => import('../views/LayOut.vue'),
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('../views/AppIndex.vue')
            },
            {
                path: '/area',
                name: 'AppArea',
                component: () => import('../views/AppArea.vue')
            },
        ]
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})


export default router