import { createStore ,Store,useStore as baseUseStore} from 'vuex'
import {InjectionKey} from 'vue'

// 为store state 声明类型
export interface State {
  userInfo:object,
}

// 定义injection key
export const key:InjectionKey<Store<State>> = Symbol()

// 导出store模块

export const store = createStore<State>({
  state:{
    userInfo:{
      avatar:'',
      nickname:'',
    },
  },
  getters:{
    getUserInfo:state=>{
      return state.userInfo
    }
  },
  mutations:{
    SET_USERINFO(state,params:object){
      state.userInfo = params;
    }
  }
})

export function userStore(){
  return baseUseStore(key)
}
