import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {store,key,userStore} from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './css/normal.css'
import './css/font.less'
import {Request} from './request/index';
import VueAxios from 'vue-axios';
const app: ReturnType<typeof createApp> = createApp(App)

// 引入全局axios
app.config.globalProperties.$axios = Request
app.config.globalProperties.$store = userStore

app.use(ElementPlus, { size: 'small', zIndex: 1000 }).use(store,key).use(router).use(VueAxios,Request.init()).mount('#app')
 