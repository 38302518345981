
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import router from '../router';


export class Request {

    // public static hostURL = 'https://zxapp.togy.com.cn/api/'
    public static hostURL = 'https://api.zxihealth.com/api/'
    // 上传文件接口
    public static uploadURL = this.hostURL + 'upload'

    public static axiosInstance: AxiosInstance;

    public static init() {
        // 创建axios实例
        this.axiosInstance = axios.create({
            baseURL: this.hostURL,
            timeout: 600000
        })
        // 初始化拦截器
        this.initInterreceptors();
        return axios
    }

    // 初始化拦截器

    public static initInterreceptors() {
        this.axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
        /**
        * 请求拦截器
        * 每次请求前，如果存在token则在请求头中携带token
        */
        this.axiosInstance.interceptors.request.use(
            (config: any): any => {
                const token = localStorage.getItem('access_token');
                if (token) {
                    config.headers['Authorization'] = token
                }
                return config;

            },
            (error: any) => {
                console.log(error);
            },
        );

        // 响应拦截器
        this.axiosInstance.interceptors.response.use(
            (response: AxiosResponse): any => {
                // 请求成功
                switch (response.status) {
                    case 200:
                        if (response.data.data) {
                            if (response.data.code == 200) {
                                return Promise.resolve(response.data.data);
                            } else if (response.data.code == 4001 || response.data.code == 999) {
                                ElMessage.warning(response.data.message);
                                localStorage.removeItem('access_token')
                                // 跳转到登录页
                                router.replace('/login')
                                return Promise.reject(response.data);
                            } else {
                                ElMessage.warning(response.data.message);
                                return Promise.reject(response.data)
                            }
                        } else {
                            return Promise.resolve(response.data);
                        }
                        break;
                    default:
                        return Promise.reject(response)
                }
            },
            (error: any) => {
                if (error.message.indexOf('timeout') != -1) {
                    ElMessage.warning('请求超时');
                } else {
                    ElMessage.warning(error.message);
                    return Promise.reject(error)
                }
            },
        )
    }

    private static errorHandle(res: any) {
        // 状态码判断
        switch (res.status) {
            case 401:
                break;
            case 403:
                break;
            case 404:
                ElMessage.warning('请求的资源不存在');
                break;
            default:
                ElMessage.warning('连接错误');
        }
    }
}